<template>
  <div class="tile is-ancestor is-parent full-quote-page">
    <div class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <div class="columns is-gapless">
          <div class="column">
            <div class="columns is-gapless">
              <div class="column">
                <p class="title">Miscellaneous</p>
                <subtitle-totals :labels="['Misc Total', 'Grand Total']"
                  :totals="[miscTotal, innerValue.totalExGst]"
                  :shows="[true, true]" />
              </div>
              <div class="column">
                <div class="control is-pulled-right">
                  <div v-if="!audanetLock"
                    class="buttons">
                    <button v-if="value.subQuoteNo > 0 && !innerValue.readOnly && !readOnlyView"
                      class="button is-success tooltip is-tooltip-topright"
                      data-tooltip="Adjust Main Quote Items"
                      @click="toggleAvailableItemsMain()">
                      <span class="icon">
                        <i class="mdi"
                          :class="{ 'mdi-adjust' : !isAvailableItemsMainActive, 'mdi-close' : isAvailableItemsMainActive }" />
                      </span>
                      <span>{{ isAvailableItemsMainActive ? 'Done' : 'Adjust' }}</span>
                    </button>
                    <button class="button is-primary tooltip is-tooltip-topright"
                      data-tooltip="Toggle Deleted Parts Visibility"
                      @click="()=>{displayDeleted = !displayDeleted}">
                      <span class="icon">
                        <i class="mdi"
                          :class="{ 'mdi-eye-off' : displayDeleted, 'mdi-eye' : !displayDeleted }" />
                      </span>
                      <span>{{ displayDeleted ? 'Hide' : 'Show' }}</span>
                    </button>
                    <button v-if="!innerValue.readOnly && !readOnlyView"
                      class="button is-primary tooltip is-tooltip-topright"
                      data-tooltip="Add new misc"
                      @click="toggleAvailableItems()">
                      <span class="icon">
                        <i class="mdi"
                          :class="{ 'mdi-plus' : !isAvailableItemsActive, 'mdi-minus' : isAvailableItemsActive }" />
                      </span>
                      <span>{{ isAvailableItemsActive ? 'Close' : 'Add' }}</span>
                    </button>
                    <button v-if="!innerValue.readOnly && !readOnlyView"
                      class="button is-primary tooltip is-tooltip-topright"
                      data-tooltip="Add blank item"
                      @click="addBlankItem">
                      <span class="icon">
                        <i class="mdi mdi-shape-square-plus" />
                      </span>
                    </button>
                  </div>
                  <div v-if="audanetLock && !$showSpinnerRequestCount">
                    <audanet-lock-notification />
                  </div>
                </div>
              </div>
            </div>
            <available-miscs v-if="isAvailableItemsActive"
              v-model="innerValue.miscs"
              :quote-id="value.quoteId"
              :labour-type="value.labourType"
              :next-line-number="nextLineNumber"
              @item-added="onItemAdded" />
            <available-miscs-main v-if="isAvailableItemsMainActive"
              v-model="innerValue.miscs"
              :quote-id="value.quoteId"
              :quote-no="value.quoteNo"
              :sub-quote-no="value.subQuoteNo"
              :labour-type="value.labourType"
              :next-line-number="nextLineNumber"
              @item-added="onItemAdded" />
            <div v-if="isAvailableItemsActive || isAvailableItemsMainActive"
              class="is-divider" />
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              :draggable="true"
              :show-pagination="false"
              @pageChanged="onPageChange"
              @sort="sort">
              <draggable v-model="innerValue.miscs"
                :tag="'tbody'"
                v-bind="{'disabled': !isRowDraggable, chosen: 'chosen', ghostClass: 'draggable-ghost', dragClass: 'draggable-drag'}"
                @change="itemMoved"
                @end="endMove"
                handle=".item-drag-handle">
                <tr v-for="(item, index) in activeItems"
                  :key="item.quoteItemId"
                  :class="[defaultRowColourClass(item), rowDisplayStatus(item, getItemAssessments(item))]"
                  @click="highlightSelected(index, $event)"
                  :data-itemid="item.quoteItemId">
                  <td v-show="$userInfo.isSupportUser"
                    class="has-vertical-middle has-text-right linenumber-input">
                    <vue-numeric v-if="!innerValue.readOnly && $userInfo.isSupportUser && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
                      class="input has-text-right"
                      :class="defaultRowColourClass(item)"
                      v-model.number="item.lineNumber"
                      :precision="0"
                      separator="" />
                    <span v-else>{{ item.lineNumber }}</span>
                  </td>
                  <td class="has-vertical-middle itemno-input">
                    <input v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="input"
                      :class="[defaultRowColourClass(item), !isValidItemNo(item.quoteItemId) ? 'is-danger': '']"
                      placeholder="Item No."
                      v-model="item.itemNo">
                    <span v-else>{{ item.itemNo }}</span>
                  </td>
                  <td :class="['has-vertical-middle itemdesc-input',defaultCellColourClass(item,'itemDesc')]"
                    :content="getTooltipCommunicationHistoryForField(item, 'itemDesc')"
                    v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'itemDesc')">
                    <input v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="input"
                      :class="[defaultRowColourClass(item), !isValidItemDesc(item.quoteItemId) ? 'is-danger': '']"
                      placeholder="Item Description"
                      v-model="item.itemDesc">
                    <span v-else>{{ item.itemDesc }}</span>
                    <div v-if="item.itemComment"
                      class="help is-primary">
                      <span class="has-text-weight-bold">Comment: </span>
                      <span>{{ item.itemComment }}</span>
                    </div>
                  </td>
                  <td class="has-vertical-middle has-text-centered">
                    <div v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                      <input v-model="item.reportOnly"
                        type="checkbox">
                      <div class="state p-info">
                        <i class="icon mdi mdi-check" />
                        <label />
                      </div>
                    </div>
                    <span v-else
                      class="icon has-text-info">
                      <i class="mdi mdi-24px"
                        :class="[ item.reportOnly ? 'mdi-check' : '' ]" />
                    </span>
                  </td>
                  <td class="has-vertical-middle has-text-centered is-content-width">{{ item.quantityOrdered }}</td>
                  <td class="has-vertical-middle has-text-centered is-content-width">{{ item.quantityReceived }}</td>
                  <td :class="['has-vertical-middle has-text-right money-input',defaultCellColourClass(item,'value')]"
                    :content="getTooltipCommunicationHistoryForField(item, 'value')"
                    v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'value')">
                    <vue-numeric v-if="!innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="input has-text-right"
                      :class="defaultRowColourClass(item)"
                      v-model.number="item.value"
                      :disabled="item.quantityReceived>0 && !$userInfo.isSupportUser"
                      :precision="2"
                      :minus="true"
                      @input="updateValue(index,item)" />
                    <span v-else>{{ item.value | formatNumber($userInfo.locale) }}</span>
                  </td>
                  <td class="has-vertical-middle has-text-centered">
                    <div v-if="item.presentAs">
                      <div v-for="split in item.presentAs"
                        :key="split.itemType">
                        <div class="tags has-addons mb-0 present-as-tags">
                          <span :class="`tag mb-1 is-${split.itemType.toLowerCase()}`">{{ split.itemType }}</span>
                          <span class="tag is-dark mb-1">{{ split.percent }}%</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td v-show="$userInfo.isSupportUser"
                    class="has-vertical-middle itemstatus-input">
                    <input v-if="!innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
                      class="input is-capitalized"
                      v-model="item.itemStatus">
                    <span v-else>{{ item.itemStatus }}</span>
                  </td>
                  <td class="has-vertical-middle has-text-centered is-content-width">
                    <div class="icon-container items-center">
                      <supplementary-icon v-if="innerValue.quoteAssessments.length > 0"
                        :item="item"
                        :item-assessments="getItemAssessments(item)"
                        :next-assessment-number="nextSupplemetryNo"
                        :quoting-method="innerValue.quotingMethod"
                        :icon-size="4"
                        class="icon" />
                      <button v-if="!audanetLock && !item.isDeleted"
                        class="button is-danger is-small is-inverted tooltip"
                        @click="deleteItem(item, index)"
                        :disabled="innerValue.readOnly || readOnlyView || isItemRemoved(item, getItemAssessments(item))"
                        data-tooltip="Delete">
                        <span class="icon is-medium">
                          <i class="mdi mdi-delete mdi-24px" />
                        </span>
                      </button>
                      <button v-if="!isItemRemoved(item, getItemAssessments(item)) && (item.isDeleted === false || item.deleted === false)"
                        class="button is-primary-dark is-small is-inverted tooltip"
                        @click="showPresentAsModal(item, index)"
                        :disabled="isItemRemoved(item, getItemAssessments(item))"
                        data-tooltip="Present As">
                        <span class="icon is-medium">
                          <i class="mdi mdi-format-page-split mdi-24px" />
                        </span>
                      </button>
                      <button class="button is-primary is-small is-inverted item-drag-handle pr-0 pl-1"
                        :disabled="innerValue.readOnly || readOnlyView">
                        <span class="icon is-medium">
                          <i class="mdi mdi-drag-vertical mdi-24px" />
                        </span>
                      </button>
                    </div>
                  </td>
                </tr>
              </draggable>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
              <template slot="footer">
                <tr>
                  <th class="has-text-right has-text-success"
                    :colspan="totalColSpan">Totals</th>
                  <th class="has-text-right has-text-success"
                    :class="[ innerValue.miscTotal >= 0 ? 'has-text-success' : 'has-text-danger' ]">{{ innerValue.miscTotal | formatCurrency($userInfo.locale) }}</th>
                  <th colspan="100%" />
                </tr>
              </template>
            </bulma-table>
          </div>
        </div>
      </article>
    </div>
    <totals-floating-widget :active.sync="isFloatingWidgetActive"
      title="Misc Totals"
      :totals="miscTotal"
      :total-ex-gst="innerValue.totalExGst" />
    <quote-item-present-as-modal v-if="isPresentAsModalActive"
      :active.sync="isPresentAsModalActive"
      v-model="selectedItem.presentAs"
      @ok="closeModal()"
      @cancel="closeModal()"
      @close="closeModal()">
      <p slot="text-title">Present As</p>
    </quote-item-present-as-modal>
  </div>
</template>

<script>
import BulmaTable from '@/components/BulmaTable'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { MiscColumns } from './columns'
import { QuoteItemStatusTypes, EventHubTypes, ItemCategoryTypes } from '@/enums'
// import _orderBy from 'lodash/orderBy'
import VueNumeric from '@/components/VueNumeric'
import { TotalsFloatingWidget, AvailableMiscs, AvailableMiscsMain, SupplementaryIcon } from './components'
import { QuoteAssessmentMixin, QuoteItemDifferenceMixin, QuoteItemValidationMixin, QuoteMiscMixin } from './mixins'
import { QuoteItemModel } from '@/classes/viewmodels'
import draggable from 'vuedraggable'
import SubtitleTotals from '@/components/SubtitleTotals'
import { QuoteItemPresentAsModal } from '@/components/BulmaModal'

export default {
  name: 'QuoteMisc',
  components: {
    BulmaTable,
    VueNumeric,
    AvailableMiscs,
    TotalsFloatingWidget,
    AvailableMiscsMain,
    SupplementaryIcon,
    SubtitleTotals,
    draggable,
    QuoteItemPresentAsModal
  },
  mixins: [QuoteItemDifferenceMixin, NumberFiltersMixin, QuoteItemValidationMixin, QuoteMiscMixin, QuoteAssessmentMixin],
  props: {
    value: null,
    nextLineNumber: {
      type: Number,
      default: 0
    },
    readOnlyView: {
      type: Boolean,
      default: false
    },
    audanetLock: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      displayDeleted: false,
      innerValue: null,
      // items: null,
      selectedRow: null,
      // totalRows: 0,
      isTableLoading: false,
      filter: {
        sortColumn: MiscColumns[1].name,
        sortOrder: MiscColumns[1].defaultOrder,
        pageIndex: 1,
        pageSize: 100
      },
      isAvailableItemsActive: false,
      isFloatingWidgetActive: true,
      isAvailableItemsMainActive: false,
      isPresentAsModalActive: false,
      selectedItem: null
    }
  },
  computed: {
    columns() {
      const c = MiscColumns
      c[1].isVisible = this.$userInfo.isSupportUser
      c[c.length - 2].isVisible = this.$userInfo.isSupportUser
      return MiscColumns
    },
    activeItems() {
      return this.items.filter((i) => this.isItemDeleted(i, this.getItemAssessments(i)) === false)
    },
    totalRows() {
      return this.activeItems.length
    },
    items() {
      if (this.innerValue) {
        return this.innerValue.miscs //.filter((i) => !i.deleted)
      }
    },
    totalColSpan() {
      return this.columns.length - (this.$userInfo.isSupportUser ? 5 : 6)
    },
    isRowDraggable() {
      return !this.innerValue.readOnly
    },
    itemsAssessments() {
      return this.getMiscItemsAssessments()
    },
    nextSupplemetryNo() {
      const nextSuppNo = Math.max(...this.value.quoteAssessments.map((i) => i.subQuoteNo)) + 1
      return nextSuppNo
    }
  },
  watch: {
    // value: {
    //   handler: function(newVal, oldVal) {
    //     this.innerValue = newVal
    //     this.filter.sortColumn = ''
    //     this.filter.sortOrder = ''
    //     // this.items = _orderBy(this.innerValue.miscs.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
    //   },
    //   deep: true
    // },
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    this.innerValue = this.value
    // this.items = _orderBy(this.innerValue.miscs.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
    // this.items = _orderBy(this.value.items.filter(i => i.itemType === ItemCategoryTypes.MISC && !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = this.value
    })
    this.$v.entity.miscs.$touch()
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
  },
  methods: {
    highlightSelected(index, event) {
      // this.selectedRow = index
    },
    onPageChange(pageIndex) {},
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      // this.items = _orderBy(this.innerValue.miscs.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
    },
    updateTotal(index) {
      this.isFloatingWidgetActive = true
    },
    deleteItem(item, index) {
      let originalItemStatus = item.itemStatus
      if (item.isNew) {
        const itemIndex = this.innerValue.miscs
          .map(function (obj) {
            return obj.quoteItemId
          })
          .indexOf(item.quoteItemId)
        if (itemIndex >= 0) {
          this.innerValue.miscs.splice(itemIndex, 1)
          // this.items.splice(index, 1)
        }
      } else {
        item.deleted = true
        item.isDeleted = true
        item.itemStatus = QuoteItemStatusTypes.Deleted
        item.originalItemStatus = originalItemStatus
      }
      this.updateTotal()
      this.$snackbar.open({
        message: `Item <span class="is-italic">${item.itemDesc}</span> deleted`,
        type: 'is-danger',
        position: 'is-bottom',
        actionText: 'Undo',
        onAction: () => {
          if (item.isNew) {
            this.innerValue.miscs.splice(this.innerValue.miscs.length, 1, item)
            // this.items.splice(this.items.length, 1, item)
          } else {
            item.deleted = false
            item.isDeleted = false
            item.itemStatus = originalItemStatus
          }
          this.updateTotal()
        }
      })
    },
    restoreItem(item, index) {
      item.deleted = false
      item.isDeleted = false
      //TODO: must set item status to original item status
      this.updateTotal()
    },
    toggleAvailableItems() {
      this.isAvailableItemsMainActive = false
      this.isAvailableItemsActive = !this.isAvailableItemsActive
    },
    toggleAvailableItemsMain() {
      this.isAvailableItemsActive = false
      this.isAvailableItemsMainActive = !this.isAvailableItemsMainActive
    },
    onItemAdded(newItem) {
      this.filter.sortColumn = ''
      this.filter.sortOrder = ''
      this.innerValue.lines = newItem.lineNumber + 1
      this.innerValue.miscs.splice(this.innerValue.miscs.length, 1, newItem)
      // this.items = _orderBy(this.innerValue.miscs.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
      this.$toast.open({
        message: 'Item added',
        type: 'is-success',
        position: 'is-bottom',
        queue: false
      })
    },
    addBlankItem() {
      let newItem = new QuoteItemModel(this.innerValue.quoteId, '', '', ItemCategoryTypes.MISC)
      newItem.lineNumber = this.nextLineNumber
      newItem.itemNo = this.$filters.pad(newItem.lineNumber, 4)
      newItem.value = 0
      newItem.side = ''
      newItem.sortNo = this.innerValue.miscs.length ? Math.max(...this.innerValue.miscs.map((i) => i.sortNo)) + 1 : 1
      this.innerValue.miscs.splice(this.innerValue.miscs.length, 1, newItem)
      this.innerValue.lines = this.nextLineNumber + 1
      this.$toast.open({
        message: 'Item added',
        type: 'is-success',
        position: 'is-bottom',
        queue: false
      })
    },
    itemMoved(event) {
      // console.log(event.moved)
      let sortNo = Math.min(...this.innerValue.miscs.map((i) => i.sortNo))
      this.innerValue.miscs.forEach((item) => {
        item.sortNo = sortNo
        sortNo++
      })
    },
    endMove(event) {},
    closeModal() {
      this.isPresentAsModalActive = false
    },
    showPresentAsModal(item, index) {
      this.selectedItem = item
      this.$nextTick(() => {
        this.isPresentAsModalActive = true
      })
    },
    getMiscItemsAssessments() {
      return this.getItemsAssessments(this.items, this.innerValue.quoteAssessments)
    },
    getItemAssessments(item) {
      const itemAssessments = this.itemsAssessments.find((i) => i.quoteItemId === item.quoteItemId)
      if (itemAssessments) {
        return itemAssessments.assessments
      }
      return []
    }
  }
}
</script>

<style lang="scss" scoped>
.linenumber-input {
  width: 60px;
}
.itemno-input {
  max-width: 2.5em;
}
.itemdesc-input {
  min-width: 20em;
}
.money-input {
  max-width: 4em;
}
.qty-input {
  max-width: 1.8em;
}
.partno-input {
  max-width: 6.5em;
}
.itemstatus-input {
  width: 50px;
}
.item-type-tag {
  width: 52px;
}
.present-as-tags {
  display: inline-flex;
  .tag {
    font-size: 0.6rem !important;
  }
}
</style>
